import React from "react";
import {
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import {  FaFacebook, FaLinkedin } from "react-icons/fa";

import { images } from "../constants";

const Footer = () => {
  return (
    <section className="bg-footer-bg bg-center bg-cover">
    <hr />
      <footer className="container mx-auto grid grid-cols-2 px-5 py-10 gap-y-10 gap-x-5 md:pt-20 md:grid-cols-2 lg:grid-cols-2 lg:gap-x-10">
        <div className="col-span-5 md:col-span-4 lg:col-span-2">
          <h3 className="text-[#0D6EFD] font-bold md:text-lg">QUICK LINKS</h3>
          <ul className="text-[#959EAD] text-sm mt-5 space-y-4 md:text-base">
            <li>
              <a className="hover:text-white" href="https://ecellrgpv.com/">Home</a>
            </li>
            <li>
              <a className="hover:text-white" href="https://ecellrgpv.com/about">About us</a>
            </li>
            <li>
              <a className="hover:text-white" href="https://ecellrgpv.com/contact">Contact us</a>
            </li>
            <li>
              <a className="hover:text-white" href="https://ecellrgpv.com/gallery">Gallery</a>
            </li>
            <li>
              <a className="hover:text-white" href="https://ecellrgpv.com/postevents">Post Events</a>
            </li>
            <li>
              <a className="hover:text-white" href="https://ecellrgpv.com/cap">CAP</a>
            </li>
          </ul>
        </div>
        <div className="col-span-5 md:col-span-4 lg:col-span-2">
          <h3 className="text-[#0D6EFD] font-bold md:text-lg">SPOTLIGHT</h3>
          <ul className="text-[#959EAD] text-sm mt-5 space-y-4 md:text-base">
            <li>
              <a className="hover:text-white" href="https://ecellrgpv.com/sip">SIP</a>
            </li>
            <li>
              <a className="hover:text-white" href="https://ecellrgpv.com/startupIncubation">Startup Incubation</a>
            </li>
            <li>
              <a className="hover:text-white" href="https://ecellrgpv.com/e-mentoring">E-Mentoring</a>
            </li>
            <li>
              <a className="hover:text-white" href="https://ecellrgpv.com/skillsDevelopment">Skill Development</a>
            </li>
            <li>
              <a className="hover:text-white" href="https://ecellrgpv.com/alumni">Alumni E-Cell RGPV</a>
            </li>
          </ul>
        </div>
        {/* <div className="col-span-5 md:col-span-4 md:col-start-5 lg:col-start-auto lg:col-span-2">
          <h3 className="text-dark-light font-bold md:text-lg">Company</h3>
          <ul className="text-[#959EAD] text-sm mt-5 space-y-4 md:text-base">
            <li>
              <a href="/">About</a>
            </li>
            <li>
              <a href="/">Terms</a>
            </li>
            <li>
              <a href="/">Privacy Policy</a>
            </li>
            <li>
              <a href="/">Careers</a>
            </li>
          </ul>
        </div> */}
        <div className="col-span-5 md:col-span-4 lg:col-span-2">
          <h3 className="text-[#0D6EFD] font-bold md:text-lg">CONTACT US</h3>
          <ul className="text-[#959EAD] text-sm mt-5 space-y-4 md:text-base">
            <li>
              <a className="hover:text-white" href="/">E-Cell RGPV, Gandhi Nagar, Bhopal</a>
            </li>
            <li>
              <a className="hover:text-white" href="/">Phone: +91 8985412487</a>
            </li>
            <li>
              <a className="hover:text-white" href="/">Email: support@ecellrgpv.com</a>
            </li>
          </ul>
        </div>
        <div className="col-span-10 md:order-first md:col-span-4 lg:col-span-2">
          
          <a href="https://ecellrgpv.com/">
          <img
            src={images.Logo}
            alt="logo"
            className="brightness-0 invert w-20 mx-auto md:mx-0"
          />
          </a>
          <p className="text-sm text-dark-soft text-center mt-4 md:text-left md:text-base lg:text-sm">
          e-Entrepreneurship Cell is a non-profit organisation solely with the purpose of creating awareness towards the developing entrepreneurial culture in our surrounding and encourage students to embrace the idea of starting their own venture and also to inspire and guide young entrepreneurs and their start-ups.
          </p>
          <ul className="flex justify-center items-center mt-5 space-x-4 text-gray-300 md:justify-start">
          <li>
              <a className="hover:text-[#0D6EFD] transition-all duration-300" href="https://www.facebook.com/ECellRGPV">
                <FaFacebook className="w-6 h-auto" />
              </a>
            </li>
            
            <li>
              <a className="hover:text-[#0D6EFD] transition-all duration-300" href="https://www.instagram.com/ecell_rgpv">
                <AiFillInstagram className="w-6 h-auto" />
              </a>
            </li>

            <li>
              <a className="hover:text-[#0D6EFD] transition-all duration-300" href="https://www.linkedin.com/company/e-entrepreneurship-cell-rgpv">
                <FaLinkedin className="w-6 h-auto" />
              </a>
            </li>

          </ul>
        </div>
        <div className="hidden md:flex flex-col items-center space-y-4 md:col-span-12 lg:col-span-10">
          {/* <div className="bg-primary text-white p-3 rounded-full">
            <AiFillHeart className="w-7 h-auto" />
          </div> */}
          <p className="font-bold italic text-dark-soft">
            Copyright © 2016-2021 E-Cell RGPV. All Rights Reserved.
          </p>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
